<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/index')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"></div>
				</div>
			</div>
		</div>
		<div class="item-detail-content" v-if="item">
			<div class="item">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-carousel height="350px" style="cursor: pointer;">
							<el-carousel-item v-for="(pic,index) in item.slideshowPics" :key="index">
								<img class="img-list" src="../../assets/drug.jpg" v-if="item.itemType==5" />
								<img class="img-list" :src="pic" v-if="item.itemType!=5" />
							</el-carousel-item>
						</el-carousel>
					</el-col>
					<el-col :span="18">
						<div class="name"><span v-if="item.itemType==5">处方药</span>{{item.name}}</div>
						<div class="summary">
							<div class="brief">{{item.brief}}</div>
							<div class="summary-price">
								<div class="dt">售价</div>
								<div class="dd">
									<span class="p-price">
										<span>￥</span>
										<span class="price">{{item.skuList[skuIndex].isPrice}}</span>
										<span class="io-price"
											v-if="item.skuList[skuIndex].ioPrice>item.skuList[skuIndex].isPrice">￥{{item.skuList[skuIndex].ioPrice}}</span>
									</span>
								</div>
							</div>
							<div class="summary-price">
								<div class="dt">库存</div>
								<div class="dd">
									<span class="p-price">
										<span
											class="stock">{{item.skuList[skuIndex].stock>0?item.skuList[skuIndex].stock+'件':'已售罄'}}</span>
									</span>
								</div>
							</div>
							<!-- 							<div class="summary-price" v-if="code">
								<div class="dt code">二维码</div>
								<div class="dd">
									<span class="p-price">
										<img :src="code" />
										<div class="code-tip">微信扫码进入小程序购买</div>
									</span>
								</div>
							</div> -->
							<div class="summary-price" v-if="item.itemType==5">
								<div class="dt">提醒</div>
								<div class="dd">
									<span class="p-price">
										<span class="tip">处方药需要实名且凭处方购买,阿克索健康国际严格保护用药人隐私</span>
									</span>
								</div>
							</div>
						</div>
						<div class="p-choose">
							<div class="dt">选择产品</div>
							<div class="dd">
								<div class="item-list" :class="index==skuIndex?'selected':''"
									v-for="(sku,index) in item.skuList" :key="index" @click="itemChange(index)">
									<span class="item-d">
										<img :src="sku.spuPic?sku.spuPic:sku.skuPic?sku.skuPic:''"
											v-if="sku.spuPic||sku.skuPic" />
										<span>{{sku.spuName}}&nbsp;&nbsp;&nbsp;&nbsp;{{sku.skuName}}</span>
									</span>
								</div>
							</div>
						</div>
						<div style="clear: both;"></div>
						<div class="choos-btn">
							<div class="choose-amount">
								<div class="wrap-input">
									<input @change="inputChange" class="text buy-num" id="buy-num" v-model="num">
									<span class="btn-reduce btn-tool" :class="num<=1?'disabled':''"
										@click="add(false)">-</span>
									<span class="btn-add btn-tool" @click="add(true)">+</span>
								</div>
							</div>
							<div class="buy" v-if="item.itemType!=5" @click="addCart()">
								加入购物车
							</div>
							<div class="buy" v-if="item.itemType==5" @click="openImg()">
								去复诊开药
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="detail">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="put-item">
							<div class="title">热销推荐</div>
							<ul class="plist" v-for="(pitem,index) in list" :key="index"
								@click="toUrl('/item/detail?id='+pitem.id)">
								<li class="fore1">
									<div class="p-img">
										<img src="../../assets/drug.jpg" v-if="pitem.itemType==5" :alt="pitem.name" />
										<img :src="pitem.adPic" v-if="pitem.itemType!=5" :alt="pitem.name" />
									</div>
									<div class="name">
										<span v-if="pitem.itemType==5">处方药</span>
										{{pitem.name}}
									</div>
									<div class="price">
										￥{{pitem.isPrice}}
									</div>
								</li>
							</ul>
						</div>
					</el-col>
					<el-col :span="18">
						<div class="detail-box">
							<div class="detail-title">
								<span>商品介绍</span>
							</div>
							<div class="detail-param" v-if="item.params.length>0">
								<ul class="parameter-list">
									<div class="name">产品参数</div>
									<li v-for="(param,index) in item.params" :key="index">
										{{param.title}}:{{param.brief}}
									</li>
								</ul>
							</div>
							<div class="old-detail" v-if="item.oldDetail" v-html="item.oldDetail">
							</div>
							<div class="text-detail">
								<span>
									{{item.textDetail}}
								</span>
							</div>
							<div class="img-detail">
								<img v-for="(img,index) in item.detail" :key="index" :src="img" />
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<Footer></Footer>

		<el-dialog title="提示" :visible.sync="open" width="30%">
			<div>处方药需要凭处方购买(打开微信扫描下方二维码复诊开药),处方开具后点击下方确定已开处方下单</div>
			<img style="width: 200px;height: 250px;margin-left: 150px;margin-top: 10px;" src="../../assets/cf.jpg" />
			<span slot="footer" class="dialog-footer">
			    <el-button @click="open = false">取 消</el-button>
			    <el-button type="primary" @click="createOrder()">确定已开处方</el-button>
			  </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getCookie,
		setCookie,
		delCookie
	} from '@/utils/auth'
	import {
		itemDetail,
		itemList,
		itemCode
	} from '@/api/item/item'
	import {
		cartAdd,
		cartNum
	} from '@/api/member/member'
	export default {
		data() {
			return {
				params: {
					data: {
						isSell: 1,
						isPut: 1,
						itemTypes: [5, 6]
					},
					page: 1,
					pageSize: 10
				},
				list: [],
				item: null,
				skuIndex: 0,
				num: 1,
				name: '',
				code: '',
				open: false
			};
		},
		created() {
			this.getItemList()
			this.getItem()
			this.getCode()
		},
		methods: {
			openImg() {
				this.open = true
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
				location.reload()
			},
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			addCart() {
				console.log(123)
				cartAdd({
					skuId: this.item.skuList[this.skuIndex].id,
					count: this.num
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '添加成功',
							type: 'success'
						});
						this.getCartNum()
					}
				})
			},
			getCartNum() {
				cartNum({}).then(res => {
					setCookie("carNum", res.data)
				})
			},
			getItemList() {
				itemList(this.params).then(res => {
					this.list = res.data.data
				})
			},
			getCode() {
				itemCode({
					id: this.$route.query.id
				}).then(res => {
					this.code = res.data
				})
			},
			getItem() {
				itemDetail({
					id: this.$route.query.id
				}).then(res => {
					var data = res.data
					if (data.slideshowPics) {
						data.slideshowPics = data.slideshowPics.split(',')
					}
					if (data.detail) {
						data.detail = data.detail.split(',')
					}
					this.item = data
				})
			},
			add(tag) {
				var count = this.num
				if (tag) {
					if (count + 1 <= this.item.skuList[this.skuIndex].stock) {
						this.num = count + 1
					}
				} else {
					if (this.num > 1) {
						this.num = this.num - 1
					}
				}
			},
			inputChange(e) {
				if (this.num <= 0) {
					this.num = 1
				} else {
					if (this.num > this.item.skuList[this.skuIndex].stock) {
						this.num = 1
					}
				}
			},
			itemChange(index) {
				this.skuIndex = index
				this.num = 1
			},
			createOrder(){
				var data=[{
					shopId: this.item.shopId,
					useIntegral: 0,
					itemList: [{
						skuId: this.item.skuList[this.skuIndex].id,
						count: this.num,
						couponId: null
					}]
				}]
				var param = JSON.stringify(data)
				setCookie("orderItem",param)
				this.$router.push({
					name: 'orderConfirm'
				})
			}
		},
	};
</script>

<style lang='scss'>
	.item-detail-content {
		width: 80%;
		margin-left: 10%;

		.item {
			width: 100%;

			.img-list {
				width: 350px;
				height: 350px;
			}

			.name {
				font: 700 16px Arial, "microsoft yahei";
				color: #666;
				padding-top: 10px;
				line-height: 28px;
				margin-bottom: 5px;

				span {
					padding-left: 5px;
					padding-right: 5px;
					color: #fff;
					background-color: #e3393c;
					font-size: 12px;
					border-radius: 5px;
					margin-right: 5px;
				}
			}

			.summary {
				padding: 10px;
				background: #f3f3f3;
				min-height: 100px;

				.brief {
					padding-left: 10px;
					color: #666;
					line-height: 30px;
				}

				.summary-price {
					position: relative;
					margin-bottom: 5px;

					.dt {
						line-height: 22px;
						float: left;
						padding-left: 10px;
						font-family: simsun;
						color: #999;
						letter-spacing: 10px;
					}

					.code {
						letter-spacing: 0px;
					}

					.dd {
						margin-left: 20px;

						.p-price {
							color: #e4393c;
							margin-right: 10px;
							font-weight: bold;

							span {
								font-size: 16px;
							}

							price {
								font-size: 30px;
							}

							.io-price {
								font-size: 16px;
								color: #666;
								text-decoration: line-through;
								font-weight: normal;
								padding-left: 10px;
							}

							.stock {
								color: #666;
								font-weight: normal;
							}

							.tip {
								font-weight: bold;
								color: #e3393c;
							}
						}
					}

					img {
						width: 150px;
						height: 150px;
						padding-left: 20px;
					}

					.code-tip {
						width: 150px;
						text-align: center;
						padding: 10px;
						padding-left: 50px;
					}
				}
			}

			.p-choose {
				line-height: 40px;
				margin-top: 20px;

				.dt {
					float: left;
					padding-left: 10px;
					font-family: simsun;
					color: rgb(153, 153, 153);
					position: relative;
				}

				.dd {
					margin-left: 70px;

					.item-list {
						float: left;
						color: #666;
						background-color: #fff;
						margin-right: 7px;
						margin-bottom: 4px;
						background-color: #f7f7f7;
						position: relative;
						line-height: 40px;
						cursor: pointer;

						.item-d {
							/* border: 1px solid #e3393c; */
							color: #666;
							border: 1px solid #ccc;
							padding: 0 13px;
							display: block;
							white-space: nowrap;
							padding: 0px;

							img {
								border: 0;
								vertical-align: middle;
								width: 40px;
								height: 40px;
							}

							span {
								margin: 0px 8px;
								color: #666;
								line-height: 40px;
							}
						}
					}

					.selected {
						.item-d {
							border: 1px solid #e3393c;
							color: #666
						}
					}

					.item-list:hover {
						.item-d {
							border: 1px solid #e3393c;
							color: #666
						}
					}
				}

			}

			.choos-btn {
				margin-top: 40px;
				margin-bottom: 20px;
				padding: 0 10px;
				clear: both;

				.choose-amount {
					width: 58px;
					height: 44px;
					overflow: hidden;
					border: 1px solid #ccc;
					position: relative;
					margin-right: 10px;
					float: left;

					input {
						display: block;
						width: 43px;
						height: 42px;
						line-height: 42px;
						position: absolute;
						top: 1px;
						left: 0;
						border: none;
						border: 0;
						text-align: center;
					}

					.btn-tool {
						display: block;
						width: 15px;
						text-align: center;
						height: 22px;
						line-height: 22px;
						overflow: hidden;
						background: #f1f1f1;
						color: #666;
						position: absolute;
						right: -1px;
						border: 1px solid #ccc;
						cursor: pointer;
					}

					.btn-reduce {
						bottom: -1px;
					}

					.disabled {
						color: #ccc;
						cursor: not-allowed;
					}

					.btn-add {
						top: -1px;
					}
				}

				.buy {
					margin-right: 10px;
					float: left;
					height: 46px;
					line-height: 46px;
					padding: 0 26px;
					font-size: 18px;
					background-color: #df3033;
					color: #fff;
					cursor: pointer;
				}
			}
		}

		.detail {
			margin-top: 40px;

			.put-item {
				border: 1px solid #eee;

				.title {
					font-weight: bold;
					color: #fff;
					background-color: #e3393c;
					text-align: center;
					font-weight: bold;
					font-size: 18px;
					line-height: 40px;
				}

				.plist {
					cursor: pointer;
					width: 80%;
					margin-left: 10%;
					margin-top: 20px;

					.fore1 {
						width: 100%;
						text-align: center;

						.p-img {
							width: 160px;
							height: 160px;
							margin: 0 auto;

							img {
								width: 160px;
								height: 160px;
							}
						}

						.name {
							height: 48px;
							font-size: 14px;
							line-height: 24px;
							text-align: left;
							color: #333;
							-webkit-transition: color .2s ease;
							transition: color .2s ease;
							word-break: break-all;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;

							span {
								padding-left: 5px;
								padding-right: 5px;
								color: #fff;
								background-color: #e3393c;
								font-size: 12px;
								border-radius: 5px;
								margin-right: 5px;
							}
						}

						.name:hover {
							font-weight: bold;
							color: #000;
						}

						.price {
							color: #e4393c;
							font-size: 18px;
							font-weight: bold;
							text-align: right;
							padding-right: 20px;
						}
					}
				}
			}

			.detail-box {

				.detail-title {
					width: 100%;
					background-color: #f7f7f7;
					border: 1px solid #eee;
					border-bottom: 1px solid #e4393c;
					padding: 10px 0;
					font-size: 14px;

					span {
						color: #fff;
						background-color: #e3393c;
						padding: 10px 25px;
					}
				}

				.detail-param {
					padding: 0 10px 10px;
					margin-bottom: 10px;

					.name {
						padding: 20px 0 15px;
						color: #000;
						font-size: 14px;
					}

					.parameter-list {
						padding: 20px 0 15px;

						li {
							width: 200px;
							padding-left: 42px;
							float: left;
							margin-bottom: 5px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							color: #666;
							font-size: 12px;
							line-height: 20px;
						}
					}
				}

				.old-detail {
					margin-top: 20px;
				}

				.img-detail {
					font-size: 0px;
					width: 100%;

					img {
						width: 100%;
						vertical-align: top;
					}
				}
			}
		}
	}
</style>